let transitionRunning = false

const body = document.querySelector('body')
const menu = document.querySelector('#main-menu')
const menuBg = document.querySelector('.menu-bg')

const leftColumn = document.querySelector('.left-column')
const rightColumn = document.querySelector('.right-column')

const menuButtons = document.querySelectorAll('button[data-menu-button]')
const closeButtons = document.querySelectorAll('button[data-menu-close-button]')

const resetScrollPosition = () => {
    leftColumn.scrollTop = 0
    rightColumn.scrollTop = 0
}

// A11Y Esc close
document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape' && menuBg.classList.contains('open')) {
        body.classList.remove('no-scroll')
        menu.classList.remove('open')
        menu.classList.remove('mobile-view-right')
        menu.classList.remove('mobile-view-left')
        menuBg.classList.remove('open')
    }
})

// handle open button
for (const button of menuButtons) {
    button.addEventListener('click', () => {
        body.classList.add('no-scroll')
        menuBg.classList.add('open')
        menu.classList.add('open')
    })
}

// handle close button
for (const button of closeButtons) {
    button?.addEventListener('click', () => {
        body.classList.remove('no-scroll')
        menu.classList.remove('open')
        menu.classList.remove('mobile-view-right')
        menu.classList.remove('mobile-view-left')
        menuBg.classList.remove('open')
    })
}

// handle left sheet clicks
for (const el of document.querySelectorAll('.left-sheet [data-links] a[data-has-submenu]')) {
    el.addEventListener('click', (e) => {
        e.preventDefault()


        // for mobile view change columns
        if (window.matchMedia("(max-width: 768px)").matches) {
            menu.classList.remove('mobile-view-left')
            menu.classList.add('mobile-view-right')
        }

        // skip if already active or transition is running
        if (e.currentTarget.classList.contains('active') || transitionRunning) {
            return
        }

        // change the active link on click
        const oldActiveLink = document.querySelector('.left-sheet [data-links] a.active')
        const newSheet = document.querySelector(
            `.right-sheet[data-id='${e.currentTarget.dataset.childId}']`,
        )
        const currentSheet = document.querySelector('.right-sheet.active')

        transitionRunning = true
        resetScrollPosition()

        oldActiveLink.classList.remove('active')
        e.currentTarget.classList.add('active')


        newSheet.classList.add('from-right')
        currentSheet.classList.add('to-left')

        // remove classes and add active after transition
        currentSheet.addEventListener(
            'transitionend',
            () => {
                currentSheet.classList.remove('active', 'to-left')
                newSheet.classList.add('active')
                newSheet.classList.remove('from-right')
                transitionRunning = false
            },
            { once: true },
        )
    })
}

// handle clicks inside right sheet
for (const el of document.querySelectorAll('.right-sheet [data-links] a[data-has-submenu]')) {
    el.addEventListener('click', (e) => {
        e.preventDefault()

        if (transitionRunning) {
            return
        }

        const currentRightSheet = document.querySelector('.right-sheet.active')
        const newLeftSheet = document.querySelector(
            `.left-sheet[data-id='${currentRightSheet.dataset.id}']`,
        )
        const currentLeftSheet = document.querySelector('.left-sheet.active')
        const newRightSheet = document.querySelector(
            `.right-sheet[data-id='${e.currentTarget.dataset.childId}']`,
        )

        transitionRunning = true
        resetScrollPosition()

        newLeftSheet.classList.add('from-right')
        currentLeftSheet.classList.add('to-left')

        // start transition classes
        newRightSheet.classList.add('from-right')
        currentRightSheet.classList.add('to-left')

        // clean up active classes on left links
        currentLeftSheet.querySelector('[data-links] a.active').classList.remove('active')
        newLeftSheet
            .querySelector(`[data-links] li a[data-child-id='${newRightSheet.dataset.id}']`)
            .classList.add('active')

        currentLeftSheet.addEventListener(
            'transitionend',
            () => {
                currentLeftSheet.classList.remove('active', 'to-left')
                currentRightSheet.classList.remove('active', 'to-left')

                newLeftSheet.classList.add('active')
                newLeftSheet.classList.remove('from-right')
                newRightSheet.classList.add('active')
                newRightSheet.classList.remove('from-right')

                transitionRunning = false
            },
            { once: true },
        )
    })
}

//handle back button
for (const el of document.querySelectorAll('.left-sheet [data-back-button] a, .right-sheet [data-back-button] a')) {
    el.addEventListener('click', (e) => {
        e.preventDefault()

        if (transitionRunning) {
            return
        }

        const currentLeftSheet = document.querySelector('.left-sheet.active')
        const currentRightSheet = document.querySelector('.right-sheet.active')

        // for mobile view just change columns to get to root
        if (window.matchMedia("(max-width: 768px)").matches && currentRightSheet.dataset.depth === '1') {
            menu.classList.remove('mobile-view-right')
            menu.classList.add('mobile-view-left')
            return
        }

        const newRightSheet = document.querySelector(
            `.right-sheet[data-id='${currentRightSheet.dataset.parentId}']`,
        )
        const newLeftSheet = document.querySelector(
            `.left-sheet[data-id='${newRightSheet.dataset.parentId}']`,
        )

        transitionRunning = true
        resetScrollPosition()

        newLeftSheet.classList.add('from-left')
        currentLeftSheet.classList.add('to-right')
        newRightSheet.classList.add('from-left')
        currentRightSheet.classList.add('to-right')

        currentLeftSheet.parentElement.style.flexDirection = 'row-reverse'
        currentRightSheet.parentElement.style.flexDirection = 'row-reverse'

        // clean up active classes on left links
        currentLeftSheet.querySelector('[data-links] a.active').classList.remove('active')
        newLeftSheet
            .querySelector(`[data-links] li a[data-child-id='${newRightSheet.dataset.id}']`)
            .classList.add('active')

        currentLeftSheet.addEventListener(
            'transitionend',
            () => {
                currentLeftSheet.classList.remove('active', 'to-right')
                currentRightSheet.classList.remove('active', 'to-right')

                currentLeftSheet.parentElement.style.flexDirection = 'row'
                currentRightSheet.parentElement.style.flexDirection = 'row'

                newLeftSheet.classList.add('active')
                newLeftSheet.classList.remove('from-left')
                newRightSheet.classList.add('active')
                newRightSheet.classList.remove('from-left')

                transitionRunning = false
            },
            { once: true },
        )
    })
}
