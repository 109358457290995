// in stock machines
const inStockMachinesDialog = document.getElementById('in-stock-machines');
const formResult = document.getElementById('form-result-section');

document.addEventListener("d365mkt-afterformload", (() => {
    // Set the field visible for debugging purposes
    // document.querySelector('[data-targetproperty="con_websiteinformation"]').style.removeProperty('display');

    if (inStockMachinesDialog) {
        window.setHField = (hNumber = null) => {
            inStockMachinesDialog.showModal()
            inStockMachinesDialog.querySelector('[data-targetproperty="con_websiteinformation"]').querySelector('input').value = hNumber ?? '';
        }
    } else if (formResult) {
        if (document.getElementById('machining-form')) {
            fetchLead('machining-form', '/product-lead')
        } else if (document.getElementById('automation-form')) {
            fetchLead('automation-form', '/product-lead-automation')
        }
    }
}));

const fetchLead = (formId, fetchRoute) => {
    const dialog = formResult.querySelector('dialog');

    document.getElementById(formId).addEventListener('submit', function(event) {
        // prevent site reload
        event.preventDefault();

        const formData = new FormData(this);

        fetch(fetchRoute, {
            method: 'POST',
            body: formData
        })
            .then(response => response.text())
            .then(data => {
                dialog.showModal()
                document.querySelector('[data-targetproperty="con_websiteinformation"]').querySelector('input').value = data ?? '';
            });
    });
}